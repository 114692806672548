import React, { forwardRef } from 'react';
import TextField from "@mui/material/TextField";
import { NumericFormat } from 'react-number-format';

const NumericFormatWithRef = forwardRef((props, ref) => (
  <NumericFormat {...props} getInputRef={ref} />
));

const MuiInputAmount = forwardRef(({
  type = 'text',
  name,
  label,
  variant = "outlined",
  disabled = false,
  required = false,
  value,
  onChange,
  InputProps,
  onKeyUp,
  onKeyDown,
  onBlur,
  className,
  minRows,
  sx = {},
  style={},
  inputKey,
  amount,
  setAmount,
  setTotalPayableAmount,
  selectedOption,
  selectedLoan,
  currentMember,
  memberShareCheck,
  setTransType
}, ref) => {

  // Handle input change and formatting
  const handleChange = (values, name) => {
    const { floatValue } = values; // Get the formatted numeric value (as float)

    if(name !== 'insurance' && name !== 'processingfee' && name !== 'depositAmt') {
      // Set the formatted value
      setAmount(floatValue);
      if(inputKey === 'amount2_receipts') {
        floatValue>1200||currentMember.length>0&&currentMember[0].sharebalance>=1200||(memberShareCheck.length>0&&(parseInt(memberShareCheck[0].updatedShareBal)+parseFloat(floatValue))>1200)?setTransType(2):setTransType(1)
      } else if(inputKey === 'amount10_loan') {
          let totalAmount;
          if (selectedOption === 'Pen') {
              totalAmount = parseInt(selectedLoan.loanbalanceamount) + parseFloat(floatValue);
          } else {
              totalAmount = parseInt(selectedLoan.loanbalanceamount) - parseFloat(floatValue);
          }
          setTotalPayableAmount(totalAmount);
      } 
    } else {
      setAmount(prev => { return ({ ...prev, [name]: floatValue }) });
    }
  };

  const defaultStyles = {
    width: "100%",
    backgroundColor: "#FFF",
    fontSize: "14px",
    color: "#101010",
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "black", // For WebKit browsers
    },
    ...sx,
  };

  return (
    <TextField
      inputRef={ref}
      minRows={minRows}
      type={type}
      label={label}
      name={name}
      value={amount}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      className={className}
      variant={variant}
      disabled={disabled}
      required={required}
      sx={defaultStyles}
      style={style}
      InputLabelProps={{ shrink: !!value || undefined }}
      InputProps={{
        ...InputProps,
        inputComponent: NumericFormatWithRef,
        inputProps: {
          value: amount,
          onValueChange: (values) => handleChange(values, name),
          thousandSeparator: true,
          decimalSeparator:".",
          decimalScale:2,
          // fixedDecimalScale:true,
          // prefix:"RM ",
          allowNegative:false
        }
      }}
    />
  );
})

export default MuiInputAmount;